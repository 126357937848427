@font-face {
  font-family: "Noto Sans KR";
  font-style: normal;
  font-weight: 100;
  src: url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Thin.woff2)
      format("woff2"),
    url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Thin.woff)
      format("woff"),
    url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Thin.otf)
      format("opentype");
}

@font-face {
  font-family: "Noto Sans KR";
  font-style: normal;
  font-weight: 300;
  src: url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Light.woff2)
      format("woff2"),
    url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Light.woff)
      format("woff"),
    url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Light.otf)
      format("opentype");
}

@font-face {
  font-family: "Noto Sans KR";
  font-style: normal;
  font-weight: 400;
  src: url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Regular.woff2)
      format("woff2"),
    url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Regular.woff)
      format("woff"),
    url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Regular.otf)
      format("opentype");
}

@font-face {
  font-family: "Noto Sans KR";
  font-style: normal;
  font-weight: 500;
  src: url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Medium.woff2)
      format("woff2"),
    url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Medium.woff)
      format("woff"),
    url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Medium.otf)
      format("opentype");
}

@font-face {
  font-family: "Noto Sans KR";
  font-style: normal;
  font-weight: 700;
  src: url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Bold.woff2)
      format("woff2"),
    url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Bold.woff)
      format("woff"),
    url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Bold.otf)
      format("opentype");
}

@font-face {
  font-family: "Noto Sans KR";
  font-style: normal;
  font-weight: 900;
  src: url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Black.woff2)
      format("woff2"),
    url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Black.woff)
      format("woff"),
    url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Black.otf)
      format("opentype");
}

$font-00: 10px;
$font-01: 11px;
$font-02: 12px;
$font-03: 13px;
$font-04: 14px;
$font-05: 15px;
$font-06: 16px;
$font-08: 18px;
$font-20: 20px;
$font-22: 22px;
$font-24: 24px;
$font-30: 30px;

$font-family: "Noto Sans KR", sans-serif; // font-familly
$line-height: 1.4 !default;

:root {
  --font-00: #{$font-00};
  --font-01: #{$font-01};
  --font-02: #{$font-02};
  --font-03: #{$font-03};
  --font-04: #{$font-04};
  --font-05: #{$font-05};
  --font-06: #{$font-06};
  --font-08: #{$font-08};
  --font-20: #{$font-20};
  --font-22: #{$font-22};
  --font-24: #{$font-24};
  --font-30: #{$font-30};

  --font-family: #{$font-family};

  --line-height: #{$line-height};
}

:export {
  font-00: #{$font-00};
  font-01: #{$font-01};
  font-02: #{$font-02};
  font-03: #{$font-03};
  font-04: #{$font-04};
  font-05: #{$font-05};
  font-06: #{$font-06};
  font-08: #{$font-08};
  font-20: #{$font-20};
  font-22: #{$font-22};
  font-24: #{$font-24};
  font-30: #{$font-30};

  font-family: #{$font-family};
  line-height: #{$line-height};
}
