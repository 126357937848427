@import "~@mdi/font";
@import "reset";
@import "colors";
@import "fonts";
@import "size";

.page {
  padding: 15px;
}


