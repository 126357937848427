$xSmall: 20px;
$small: 28px;
$normal: 36px;
$large: 44px;
$xLarge: 52px;

:root {
  --xSmall: #{$xSmall};
  --small: #{$small};
  --normal: #{$normal};
  --large: #{$large};
  --xLarge: #{$xLarge};
}

:export {
  xSmal: #{$xSmall};
  small: #{$small};
  normal: #{$normal};
  large: #{$large};
  xLarge: #{$xLarge};
}
