html * {
  font-family: var(--font-family) !important;
  line-height: var(--line-height);
}

*:focus {
  outline: none;
}
*, *:before, *:after {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0) !important;
  outline-style: none;
  margin: 0;
  padding: 0;
}

// a
a, a:hover, a:focus, a:link, a:visited, a:-webkit-any-link, a:link{
  text-decoration: none;
}

// list
ul,ol,li,dl,dt,dd{
  list-style: none;
}

// select, input, textarea, button
fieldset , select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

input{
  font-family: var(--body-font-family) !important;
}
input:active, input:focus, button:active, button:focus, select:active, select:focus, textarea:active, textarea:focus{
  outline: none !important;
}
input[type=password] {
  font-family: Arial;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance:textfield;
}

textarea {
  width: 100%;
  height: 100%;
  padding: 6px 10px;
  resize: none;
  line-height: 16px;
  font-family: var(--body-font-family) !important;
}

p {
  -webkit-margin-before: 0;
  -webkit-margin-after: 0;
  -webkit-margin-start: 0;
  -webkit-margin-end: 0;
}

img {
  position: relative;
  -webkit-overflow-scrolling: touch;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}
body {
  position: relative;
  -webkit-overflow-scrolling: touch;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

img {
  position: relative;
  -webkit-overflow-scrolling: touch;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

html {
  height: 100%;
  width: 100%;
  position: relative;
  overflow: auto;
}
body {
  position: relative;
  -webkit-overflow-scrolling: touch;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  height: 100%;
  width: 100%;
}
